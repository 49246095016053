import { Component } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Address } from '../../../../_commons/models/address';
import { Order } from '../../../../_commons/models/order';
import { OrderService } from '../../../../_commons/services/order/order.service';
import { SiteMap, SITEMAP } from '../../../../_commons/sitemap';
import { MatIconModule } from '@angular/material/icon';
import { ToDatePipe } from "../../../../_commons/pipes/to-date/to-date.pipe";

@Component({
  selector: 'app-account-order-details',
  standalone: true,
  imports: [RouterModule, MatIconModule, ToDatePipe],
  templateUrl: './account-order-details.component.html',
  styleUrl: './account-order-details.component.scss'
})
export class AccountOrderDetailsComponent {
	public order: Order | null = null;
	public billedAddress?: Address;
	public deliveredAddress?: Address;

	public sitemap: SiteMap = SITEMAP;

	constructor(private route: ActivatedRoute, private orderService: OrderService) {}

	public async ngOnInit() {
		const idParam = this.route.snapshot.paramMap.get("id");
		const id = idParam ? parseInt(idParam, 10) : null;
		if (id) {
			this.order = await this.orderService.getOrderById(id);
			if (this.order) {
				this.billedAddress = JSON.parse(this.order.billedAddress);
				this.deliveredAddress = JSON.parse(this.order.deliveredAddress);
			}
		}
	}
}
