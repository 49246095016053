import { Injectable } from '@angular/core';
import { Order } from '../../models/order';
import pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Address } from '../../models/address';
import {
  Content,
  StyleDictionary,
  TDocumentDefinitions,
} from 'pdfmake/interfaces';
import { SHOP_ADDRESSES } from '../../constants';
import { LOGO_SVG } from './logo-svg';
import { ColissimoPriceService } from '../colissimo-price/colissimo-price.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  private taxRate: number = 0.055;

  constructor( private _colissimoPriceService: ColissimoPriceService) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

	public async generatePDF(order: Order): Promise<void> {
		const documentDefinition = this.getDocumentDefinition(order);
		pdfMake.createPdf(await documentDefinition).download(`Facture_${order.id}.pdf`);
	}

  private async getDocumentDefinition(order: Order): Promise<TDocumentDefinitions>{
    const billedAddress: Address = JSON.parse(order.billedAddress);
    const deliveredAddress: Address = JSON.parse(order.deliveredAddress);

    const shopAddress: Address = SHOP_ADDRESSES[0];

    const companyInfo = {
			name: `${shopAddress.firstName} ${shopAddress.lastName}`,
      address: `${shopAddress.street} ${shopAddress.city} ${shopAddress.zip} ${shopAddress.country}`,
      additional: `${shopAddress.lastName}`,
      email: 'contact@carrementbon.fr',
      website: 'https://carrementbon.fr/',
    };

		
		const shippingCharge = await this.calculateShippingCharge(order);

		const totalAmountInWords = this.convertNumberToWords(parseFloat(this.calculateTotal(order, shippingCharge)));

    return {
      footer: (_currentPage, _pageCount) => {
        return {
          columns: [
            {
              text: `CARREMENTBON - Code NAF (APE) 4637Z - N° RCS 848 898 862 R.C.S. Toulouse - Siret : 84889886200018 \n - N° TVA FR10 848898862`,
              alignment: 'center',
              style: 'footer',
            },
          ],
          margin: [0, 0, 0, 10],
        };
      },

      background: (_currentPage, _pageSize) => {
        return {
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: _pageSize.width,
              h: _pageSize.height,
              color: '#F7EFE6',
            },
          ],
        };
      },
      content: [
        {
          columns: [
            {
              svg: LOGO_SVG,
              width: 100,
            },
          ],
        },
        {
          columns: [
            {
              width: '50%',
              table: {
                widths: ['*'],
                body: [
                  [{ text: 'Adresse de facturation', style: 'tableHeader' }],
									[{ text: `${billedAddress.firstName} ${billedAddress.lastName}`, style: 'tableContent' }],
                  [{ text: billedAddress.street, style: 'tableContent' }],
                  [
                    {
                      text: `${billedAddress.zip} ${billedAddress.zip} ${billedAddress.city} ${billedAddress.country}`,
                      style: 'tableContent',
                    },
                  ],
                  [{ text: `Téléphone : ${billedAddress.phone}`, style: 'tableContent' }],
                  [{ text: `N° Client : ${order.user.id.toString()}`, style: 'tableContent' }],
                ],
              },
              layout: 'noBorders',
            },
            {
              width: '50%',
              table: {
                widths: ['*'],
                body: [
                  [{ text: 'Adresse de livraison', style: 'tableHeader' }],
                  [{ text: `${deliveredAddress.firstName} ${deliveredAddress.lastName}`, style: 'tableContent' }],
                  [{ text: deliveredAddress.street, style: 'tableContent' }],
                  [
                    {
                      text: `${deliveredAddress.zip} ${deliveredAddress.city} ${deliveredAddress.country}`,
                      style: 'tableContent',
                    },
                  ],
                ],
              },
              layout: 'noBorders',
            },
          ],
          columnGap: 20,
          margin: [0, 0, 0, 20],
        },
        {
          text: `Nouvelle commande passée le  ${new Date().toLocaleDateString()}`,
          style: 'header',
          margin: [0, 20, 0, 10],
        },
        { text: `Référence FR${new Date(order.editDate).getTime()}`, style: 'header', margin: [0, 20, 0, 10] },
        this.getOrderTable(order),
        {
          columns: [
            { width: '*', text: '' },
            {
              width: 'auto',
              table: {
                body: [
                  [
                    { text: 'Total HT', style: 'totalsLabel' },
                    { text: `${this.calculateSubTotal(order).toFixed(2)} €`, style: 'totalsValue' },
                  ],
                  [
										{ text: `TVA (${(this.taxRate * 100).toFixed(1)}%)`, style: 'totalsLabel' },
										{ text: `${this.calculateTax(order).toFixed(2)} €`, style: 'totalsValue' },
                  ],
									[
										{ text: 'Frais de port', style: 'totalsLabel' },
										{ text: `${shippingCharge.toFixed(2)} €`, style: 'totalsValue' },
									],
              		[
										{ text: 'Total TTC', style: 'totalsLabel' },
										{ text: `${this.calculateTotal(order, shippingCharge)} €`, style: 'totalsValue' },
									],
                ],
              },
              layout: 'lightHorizontalLines',
            },
          ],
          margin: [0, 20, 0, 20],
        },
        { text: `Le montant total s'élève à ${totalAmountInWords}`, style: 'amountInWords', margin: [0, 0, 0, 20] },
        [
          { text: companyInfo.name, style: 'companyName' },
          { text: companyInfo.address, style: 'companyInfo' },
          { text: companyInfo.additional, style: 'companyInfo' },
          { text: `Email : ${companyInfo.email}`, style: 'companyInfo' },
          { text: `Site web : ${companyInfo.website}`, style: 'companyInfo' },
        ],
      ],
      styles: this.getStyles(),
      defaultStyle: {
        fontSize: 10,
      },
    };
  }

  private getOrderTable(order: Order): Content {
    return {
      table: {
				widths: ['*', '*', 'auto', 'auto', 'auto', 'auto'],
        body: [
					[
						{ text: 'Produit', style: 'tableHeader' },
						{ text: 'Status', style: 'tableHeader' },
						{ text: 'Quantité', style: 'tableHeader' },
						{ text: `TVA (${this.taxRate * 100}%)`, style: 'tableHeader' },
						{ text: 'Prix Unitaire TTC', style: 'tableHeader' },
						{ text: 'Total TTC', style: 'tableHeader' },
					],
          ...order.cart.orderedProducts.map((item) => {
            return [
              {
                text: `${item.product.name} - ${item.price.weight} g`,
                style: 'tableContent',
              },
							{
								text: `${order.status.status}`,
								style: 'tableContent',
							},
              {
                text: item.quantity.toString(),
                style: 'tableContent',
                alignment: 'right',
              },
							{
								text: `${(item.price.value * this.taxRate).toFixed(2)} €`,
								style: 'tableContent',
								alignment: 'right',
							},
							{
								text: `${item.price.value.toFixed(2)} €`,
								style: 'tableContent',
								alignment: 'right',
							},
							{
								text: `${(item.quantity * item.price.value).toFixed(2)} €`,
								style: 'tableContent',
								alignment: 'right',
							},
            ];
          }),
        ],
      },
      layout: 'lightHorizontalLines',
    };
  }

	private getStyles(): StyleDictionary {
		return {
			h1: {
				fontSize: 22,
				bold: true,
				margin: [0, 0, 0, 10],
				color: '#331501',
			},
			h2: {
				fontSize: 18,
				bold: true,
				margin: [0, 10, 0, 5],
				color: '#331501',
			},
			h3: {
				fontSize: 16,
				bold: true,
				margin: [0, 10, 0, 5],
				color: '#331501',
			},
			paragraph: {
				fontSize: 12,
				margin: [0, 5, 0, 5],
				color: '#542302',
			},
			tableHeader: {
				fontSize: 12,
				margin: [0, 5, 0, 5],
				color: '#331501',
			},
		};
	}

	private convertNumberToWords(amount: number): string {
		const units = [
			'',
			'un',
			'deux',
			'trois',
			'quatre',
			'cinq',
			'six',
			'sept',
			'huit',
			'neuf',
			'dix',
			'onze',
			'douze',
			'treize',
			'quatorze',
			'quinze',
			'seize',
		];

		const tens = [
			'',
			'dix',
			'vingt',
			'trente',
			'quarante',
			'cinquante',
			'soixante',
			'soixante',
			'quatre-vingt',
			'quatre-vingt',
		];

		const numberToWords = (n: number): string => {
			if (n === 0) return 'zéro';

			let words = '';

			if (n >= 1000000000) {
				const billions = Math.floor(n / 1000000000);
				words += numberToWords(billions) + ' milliard' + (billions > 1 ? 's' : '');
				n %= 1000000000;
				if (n > 0) words += ' ';
			}

			if (n >= 1000000) {
				const millions = Math.floor(n / 1000000);
				words += numberToWords(millions) + ' million' + (millions > 1 ? 's' : '');
				n %= 1000000;
				if (n > 0) words += ' ';
			}

			if (n >= 1000) {
				if (n >= 1000 && n < 2000) {
					words += 'mille';
				} else {
					const thousands = Math.floor(n / 1000);
					words += numberToWords(thousands) + ' mille';
				}
				n %= 1000;
				if (n > 0) words += ' ';
			}

			if (n >= 100) {
				if (n >= 100 && n < 200) {
					words += 'cent';
				} else {
					const hundreds = Math.floor(n / 100);
					words += units[hundreds] + ' cent';
				}
				n %= 100;
				if (n > 0) words += ' ';
			}

			if (n >= 17 && n <= 19) {
				words += 'dix-' + units[n - 10];
			} else if (n >= 70 && n <= 79) {
				words += 'soixante-' + numberToWords(n - 60);
			} else if (n >= 90 && n <= 99) {
				words += 'quatre-vingt-' + numberToWords(n - 80);
			} else if (n >= 20) {
				const ten = Math.floor(n / 10);
				const unit = n % 10;
				words += tens[ten];
				if (unit === 1 && ten !== 8) {
					words += '-et-un';
				} else if (unit > 0) {
					words += '-' + units[unit];
				} else if (ten === 8 && unit === 0) {
					words += 's';
				}
			} else if (n > 0) {
				words += units[n];
			}

			return words;
		};

		const euros = Math.floor(amount);
		const centimes = Math.round((amount - euros) * 100);

		let amountInWords = numberToWords(euros) + ' euro' + (euros > 1 ? 's' : '');

		if (centimes > 0) {
			amountInWords += ' et ' + numberToWords(centimes) + ' centime' + (centimes > 1 ? 's' : '');
		}

		return amountInWords;
	}

	private async calculateShippingCharge(order: Order): Promise<number> {
		const totalWeight = order.cart.orderedProducts
			.map(item => item.price.weight * item.quantity)
			.reduce((a, b) => a + b, 0);

		const colissimoPrices = await this._colissimoPriceService.getColissimoPrices();

		const shippingOption = colissimoPrices.find((option: { weight: number }) => option.weight >= totalWeight);

		return shippingOption ? shippingOption.priceHome : 0;
	}

	private calculateSubTotal(order: Order): number {
		return order.cart.orderedProducts.reduce(
			(sum, item) => sum + item.quantity * (item.price.value - item.price.value * this.taxRate),
			0
		);
	}

	private calculateTax(order: Order): number {
		return order.cart.orderedProducts.reduce((sum, item) => sum + item.quantity * (item.price.value * this.taxRate), 0);
	}

	private calculateTotal(order: Order, shippingCharge: number): string {
		const totalProducts = order.cart.orderedProducts.reduce((sum, item) => sum + item.quantity * item.price.value, 0);
		return (totalProducts + shippingCharge).toFixed(2);
	}
}
