
<div class="cb-account-orders-section">
	<div class="cb-account-orders-title">
		<h1>Mes commandes</h1>
	</div>

	<div class="cb-account-orders-container">
		@for(order of orders; track order){
		<div class="cb-account-orders">
			<app-account-order-item [order]="order"></app-account-order-item>
		</div>
		}
	</div>
</div>