@if(order) {
	<div class="cb-account-order-details-section">
		<div class="cb-account-order-details-main-title">
			<h1>Détails de la commande</h1>
			<p>N° FR{{ (this.order.editDate | toDate).getTime() }}</p>
			<p>État : {{ this.order.status.status }}</p>
		</div>
	
		<div class="cb-account-order-details-container">
			@for(orderedProduct of order.cart.orderedProducts; track orderedProduct){
	
			<div class="cb-account-order-details">
				<div class="cb-account-order-details-item">
					<div class="cb-account-order-details-item-picture-container">
						<div class="cb-account-order-details-item-picture" [style.background-image]="'url(' + orderedProduct.product.img + ')'"></div>
					</div>
	
					<div class="cb-account-order-details-item-heading-container">
						<div class="cb-account-order-details-item-heading">
							<span class="cb-account-order-details-item-quantity"> {{ orderedProduct.quantity }} x</span>
							<a
								class="cb-account-order-details-item-title"
								[routerLink]="[this.sitemap.products.route, orderedProduct.product.id]"
								routerLinkActive="active"
								>{{ orderedProduct.product.name }}</a
							>
	
							<span class="cb-account-order-details-item-price"> ({{ orderedProduct.quantity }} x {{ orderedProduct.price.value }} €) </span>
							<span class="cb-account-order-details-item-total-price"> {{ orderedProduct.quantity * orderedProduct.price.value }} € </span>
						</div>
					</div>
				</div>
			</div>
			}
		</div>
	
		<div class="cb-account-order-details-title">
			<h2>Adresse de livraison</h2>
		</div>
	
		<div class="cb-account-order-details-address-container">
			<div class="cb-account-order-details-address">
				@if(deliveredAddress) {
				<p>{{ deliveredAddress.firstName }}, {{ deliveredAddress.lastName }}</p>
				<p>{{ deliveredAddress.country }},{{ deliveredAddress.street }}, {{ deliveredAddress.city }},</p>
				<p>
					{{ deliveredAddress.zip }}
					{{ deliveredAddress.phone }}
				</p>
				}
			</div>
		</div>
	
		<div class="cb-account-order-details-title">
			<h2>Adresse de facturation</h2>
		</div>
	
		<div class="cb-account-order-details-address-container">
			<div class="cb-account-order-details-address">
				@if(billedAddress) {
				<p>{{ billedAddress.firstName }}, {{ billedAddress.lastName }}</p>
				<p>{{ billedAddress.country }},{{ billedAddress.street }}, {{ billedAddress.city }},</p>
				<p>
					{{ billedAddress.zip }}
					{{ billedAddress.phone }}
				</p>
				}
			</div>
		</div>
	</div>
	}
	