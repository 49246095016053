@if(externalLink) {
	<a
		class="pow-button"
		mat-flat-button
		[ngClass]="buttonClasses"
		[attr.href]="externalLink"
		[attr.aria-label]="'Lien externe vers ' + (text || '')"
		target="_blank"
		rel="noopener noreferrer"
	>
	@if (this.loading) {
		<mat-progress-spinner
			diameter="26"
			mode="indeterminate"
		></mat-progress-spinner>
	} @else { {{ this.text }} }
	</a>
	} @else {
	<button class="pow-button" (click)="onClick()" mat-flat-button [ngClass]="buttonClasses" [disabled]="isDisabled" [routerLink]="routerLink">
		@if (this.loading) {
			<mat-progress-spinner
				diameter="26"
				mode="indeterminate"
			></mat-progress-spinner>
		} @else { {{ this.text }} }
	</button>
	}
	