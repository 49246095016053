import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [RouterModule, MatButtonModule, CommonModule, MatIconModule, MatProgressSpinnerModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
	@Input() public text?: string;
	@Input() public size: "big" | "big-long" | "medium" | "small" = "medium";
	@Input() public isDisabled: boolean = false;
	@Input() public type: "filled" |"text-link" = "filled";
	@Input() public shape: "round" | "default" = "default";
	@Input() public icon: string = "shopping_basket";
	@Input() public showIcon: boolean = true;
	@Input() public routerLink?: string | (string | number)[];
	@Input() public externalLink?: string;
	@Input() public loading: boolean = false;

	@Output() buttonClick = new EventEmitter<void>();

	@Input() public customColor: boolean = false;

	public onClick() {
		if (this.externalLink) {
			window.open(this.externalLink, "_blank", "noopener,noreferrer");
		} else {
			this.buttonClick.emit();
		}
	}

	get buttonClasses(): string[] {
		return [`pow-button-${this.type}`, this.size, this.shape, this.customColor ? "custom-color" : ""];
	}

	shouldShowText(): boolean {
		return this.shape !== "round" && this.text !== undefined && this.text !== null;
	}

	shouldShowIcon(): boolean {
		return this.showIcon || this.shape === "round";
	}
}
