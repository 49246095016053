import { Component, Input } from '@angular/core';
import { InvoiceService } from '../../../../_commons/services/invoice/invoice.service';
import { Order } from '../../../../_commons/models/order';
import { ButtonComponent } from "../../../../_commons/components/button/button.component";
import { ToDatePipe } from '../../../../_commons/pipes/to-date/to-date.pipe';

@Component({
  selector: 'app-account-order-item',
  standalone: true,
  imports: [ButtonComponent, ToDatePipe],
  templateUrl: './account-order-item.component.html',
  styleUrl: './account-order-item.component.scss'
})
export class AccountOrderItemComponent {
	@Input() public order!: Order;

  constructor(private invoiceService: InvoiceService) {}

  public downloadInvoice(): void {
    this.invoiceService.generatePDF(this.order);
  }
}
