@if(order) {
	<div class="cb-account-order-item">
		<div class="cb-account-order-item-container">
			<div class="cb-account-order-item-labels">
				<div class="cb-account-order-item-label">N° Commande</div>
				<div class="cb-account-order-item-label">Date</div>
				<div class="cb-account-order-item-label">Etat</div>
				<div class="cb-account-order-item-label">Facture</div>
				<div class="cb-account-order-item-label">Commande</div>
			</div>
			<div class="cb-account-order-item-values">
				<div class="cb-account-order-item-value">FR{{ (this.order.editDate | toDate).getTime() }}</div>
				<div class="cb-account-order-item-value">{{ this.order.date.toLocaleDateString() }}</div>
				<div class="cb-account-order-item-value">{{ this.order.status.status }}</div>
				<div class="cb-account-order-item-value">
					<app-button
						class="cb-button"
						(click)="downloadInvoice()"
						size="small"
						[showIcon]="true"
						icon="arrow_right_alt"
						type="text-link"
						text="Télécharger"
					></app-button>
				</div>
				<div class="cb-account-order-item-value">
					<app-button
						class="cb-button"
			
						[routerLink]="['/account/orders', order.id]"
						size="small"
						[showIcon]="true"
						icon="arrow_right_alt"
						type="text-link"
						text="Détails"
					></app-button>
				</div>
			</div>
		</div>
	</div>
	}
	